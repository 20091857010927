define(['jquery', 'siteObj'], function($, siteObj) {
  
  var config = siteObj.social;
  var _social = {

    _init: function() {
      if (window.loadJS) {

        // Add This
        if (config.addthis.show) {
          window.loadJS('//s7.addthis.com/js/250/addthis_widget.js#username='
            + config.addthis.username
            + '&async=1');
        }

        // Facebook
        if (config.facebook.show && $('#fb-root').length) {
          window.loadJS('//connect.facebook.net/' + config.facebook.locale
            + '/all.js#xfbml=1&appId='
            + config.facebook.appid);
        }

        // Google+
        if (config.googleplus.show) {
          window.loadJS('https://apis.google.com/js/plusone.js');
        }

        // Twitter
        if (config.twitter.show) {
          window.loadJS('//platform.twitter.com/widgets.js');
        }

        // Pinterest
        if (config.pinterest.show) {
          window.loadJS('//assets.pinterest.com/js/pinit.js');
        }

        // Hot UK Deals
        if (siteObj.social.hotukdeals.show) {
          window.loadJS('//social.hotukdeals.com/clientscript/social/v2/share.js');
        }
      }
    }
  };

  _social._init();

  return _social;
});
